<template>
	<v-sheet>
        <MwList storeModule="questions" :columns="headers">

        </MwList>
    </v-sheet>
</template>

<script type="text/javascript">
import MwList from "@c/ui/MwList";
export default {
	name: "Question",
    data: () => {
        return {
            filtered: false
        }
    },
	computed: {
		headers() {
			return [
				// {value: "title", text: "Title", default: true},
			];
		},
	},
	components: {
		MwList
	},
};
</script>

